import React, { Children } from "react"
import { logout } from "@multiversx/sdk-dapp/utils";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { routeNames } from "routes"

const Navbar = () => {
  const { address } = useGetAccountInfo()

  const handleLogout = () => {
    console.log(address);
    logout(`${window.location.origin}/unlock`)
  }
  const toggleMenu = () => {
    let elem = document.getElementById("navbarToggle");
    if (elem?.classList.contains('show')) {
      elem.classList.remove('show');
    } else {
      elem?.classList.add('show');
    }

  }

  const isLoggedIn = Boolean(address);
  const isPath = (path: string) => {
    return window.location.pathname == path;
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky-top" style={{ padding: '0 10%', maxWidth: '100%' }}>
      <a className="navbar-brand d-lg-none" href="/">
        <img src="assets/img/nav-logo.png" className="logo" alt="logo-f8" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggle"
        aria-controls="navbarToggle"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={toggleMenu}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-between"
        id="navbarToggle">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className={"nav-link" + (isPath('/roadmap') ? ' active' : '')} href="/roadmap">
              Roadmap <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <a className={"nav-link" + (isPath('/infinity') ? ' active' : '')} href="/infinity">
              $Infinity
            </a>
          </li>
          <li className="nav-item">
            <a className={"nav-link" + (isPath('/benefits') ? ' active' : '')} href="/benefits">
              Benefits
            </a>
          </li>
        </ul>
        <a className="navbar-brand d-none d-lg-block" href="/" >
          <img src="assets/img/nav-logo.png" className="logo" alt="logo-f8" />
        </a>
        <ul className="navbar-nav text-right">
          <li className="nav-item">
            <a className={"nav-link" + (isPath('/about') ? ' active' : '')} href="/about">
              About
            </a>
          </li>
          {/* <li className="nav-item">
            <a className={"nav-link" + (isPath('/collections') ? ' active' : '')} href="/collections">
              Collection
            </a>
          </li> */}
          <li className="nav-item">
            <a className={"nav-link" + (isPath('/store') ? ' active' : '')} href="/store">
              Store
            </a>
          </li>
          {!isLoggedIn ?
            <li className="nav-item">
              <a className="nav-link" href="/unlock">
                Connect
              </a>
            </li>
            : <li className="nav-item">
              <a className="nav-link" href="" onClick={handleLogout}>
                Disconnect
              </a>
            </li>
          }

        </ul>
      </div>
    </nav>
    // <BsNavbar className="sticky-top">
    //   {/* <Link
    //       className='d-flex align-items-center navbar-brand mr-0'
    //       to={isLoggedIn ? routeNames.mint : routeNames.home}
    //     >
    //       <MultiversXLogo className='MultiversX-logo' />
    //       <span className='dapp-name text-muted'>{dAppName}</span>
    //     </Link> */}
    //   <div className="container-fluid">
    //     <Nav className=" my_navbar">
    //       {routes.map((r, key) => {
    //         return (
    //           <Link
    //             className="d-flex align-items-center navbar-brand mr-0 navlink"
    //             to={r.path}
    //             key={key}>
    //             {r.haveOnlyLogo ? (
    //               // <MultiversXLogo className='MultiversX-logo' />
    //               <img
    //                 src="assets/img/nav-logo.png"
    //                 className="navlink__logo"
    //                 alt="logo-f8"
    //               />
    //             ) : (
    //               <span className="navlink__name">{r.title}</span>
    //             )}
    //           </Link>
    //         )
    //       })}
    //     </Nav>
    //   </div>

    //   {/*
    //     <Nav className='ml-auto'>
    //       {isLoggedIn && (
    //         <NavItem>
    //           <button className='btn btn-link' onClick={handleLogout}>
    //             Close
    //           </button>
    //         </NavItem>
    //       )}
    //     </Nav> */}
    // </BsNavbar>
  )
}

const routes = [
  { title: "ROADMAP", path: routeNames.roadmap },
  { title: "$INFINITY", path: routeNames.infinity },
  { title: "BENEFITS", path: routeNames.benefits },
  {
    title: "HOME",
    path: routeNames.home,
    haveOnlyLogo: true,
  },
  { title: "ABOUT", path: routeNames.about },
  { title: "COLLECTIONS", path: routeNames.collections },
  { title: "CONNECT", path: routeNames.walletconnect },
]

export default Navbar
