import React from "react"

const Roadmap = () => {
  return (
    <div className="section">
      <div className="container container__hero">
        <h1>Roadmap</h1>
        <div className="timeline">
          <div className="timeline__item timeline__item--img">
            <img src="assets/img/roadmap_icon.svg" alt="Diamond Image" />
          </div>
          <div className="timeline__item list">
            <div className="list__item">
              <div className="list__item--img">
                <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                <div className="divider"></div>
              </div>
              <div className="list__item--content">
                <h4>1st quarter 2022</h4>
                <p>
                  We start our nft series with the infinite stones which is a
                  collection of 8 rare gemstones representing the founding 8 In
                  animated 3D!
                </p>
                <p>
                  There will be 111 of each stone for a possible of 111 sets.
                  Our infinite stones will be available for 0.30 egld per stone
                  (0.25 per stone for full set) and collectors of all 8 (full
                  set) can hodl them for monthly $egld and or LK MEX and
                  $infinity rewards. Also holders of all 8 stones will receive
                  our Infinity club Token NFT which will be the membership to
                  our NFT club treasury.
                </p>
                <p>
                  1st quarter - Upon sellout of our infinite stones we will set
                  up a staking wallet (12.5% of sales proceeds) for the
                  community with a trusted staking provider or farm Lk MEX on
                  the maiar exchange to earn rewards for full set holders
                  monthly (vote decided) Full set holders will also earn 888
                  $infinity tokens monthly as long as they hold all 8 infinite
                  stones in their wallet.
                </p>
                <p>
                  We will also set up a infinity club members NFT wallet (12.5%
                  of sales proceeds) with MultiversX NFT’s from our favorite MultiversX
                  brands that provide esdt rewards, staking rewards, airdrop
                  rewards all to be split with the members holding the Infinity
                  token club NFT. Esdt’s and staking rewards will be split
                  evenly or raffled (voting decided) and airdrop NFT’s will be
                  raffled to our members.
                </p>
                <p>
                  The infinity token NFT will be airdropped to holders of all 8
                  infinity stones . This infinity token NFT is your membership
                  to our infinity club nft treasury.
                </p>
              </div>
            </div>
            <div className="list__item">
              <div className="list__item--img">
                <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                <div className="divider"></div>
              </div>
              <div className="list__item--content">
                <h4>2nd quarter 2022</h4>
                <p>
                  Our second series of NFT’s will be 8 infinity cyborg warriors
                  powered by our infinity stones in animated 3D.
                </p>
                <p>
                  There will be 111 of each warrior for a possible of 111 sets.{" "}
                </p>
                <p>
                  Our infinity cyborg warriors will be available for 0.30 egld
                  per cyborg warrior (0.25 each for full set) collectors of all
                  8 can hodl them for monthly $egld and or $mex and $infinity
                  rewards. Also holders of all 8 cyborg warriors will receive
                  our Infinty token NFT. Which will be membership to our NFT
                  club treasury 2nd quarter 2022- upon sellout of our infinity
                  warriors we will add 12.5% to the staking/farming wallet and
                  12.5 % to the nft club treasury.
                </p>
              </div>
            </div>
            <div className="list__item">
              <div className="list__item--img">
                <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                <div className="divider"></div>
              </div>
              <div className="list__item--content">
                <h4>3nd quarter 2022</h4>
                <p>
                  Our third series will be 8 infinity rovers powered by our
                  stones in animated 3D.
                </p>
                <p>
                  There will be 111 of each rover for a possible 111 sets. Our
                  infinity rovers will be ?? $egld per rover ( ?? Per set)
                  Collectors of all 8 can hodl them for monthly $egld and or lk
                  mex and $infinity rewards. Also holders of all 8 rovers will
                  be airdropped our infinity token club NFT which will be
                  membership to our nft club treasury.
                </p>
                <p>
                  3rd quarter 2022- upon sellout of our infinity rovers we will
                  ad 12.5% to our community staking/farming wallet and 12.5% to
                  our nft club treasury
                </p>
              </div>
            </div>
            <div className="list__item">
              <div className="list__item--img">
                <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
              </div>
              <div className="list__item--content">
                <h4>4nd quarter 2022</h4>
                <p>
                  Our generative 3D series of 8,888 will be released to grow our
                  brand details coming soon!
                </p>
                <p>
                  This is not all inclusive roadmap as there will be airdrop
                  NFT’s from our brand and other brands .There will be limited
                  edition NFT’s to purchase with the $infinity tokens.
                </p>
                <p>
                  We are also working on downloadable add ons for your warriors
                  and rovers. There will also be founding 8/infinity
                  merchandise. We will also be adding new utilities to each of
                  our series as we grow. All full set holders will be
                  automatically whitelisted for the next series.
                </p>
                <p>Stay tuned there is always more to come!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap
