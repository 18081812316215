import React from "react";
import { NFT } from "types";
import BuyButton from "./BuyButton";

const InfinityStonesCollection = () => {
    return (
        <div>
          <h1 className="text-center">The Infinity Stones</h1>
          <div className="nfts">
            {nfts.map((n, key) => (
              <div className="nfts__item" key={key}>
                <img src={n.source} alt={n.title} />
                <h5>{n.title}</h5>
              </div>
            ))}
          </div>
          <div className="text-center">
            <BuyButton label='Sold out' isActive={false} />
          </div>
        </div>
    )
}

const nfts: NFT[] = [
    {
      title: "Amethyst",
      source: "assets/img/diamonds-img/amethyst.png",
    },
    {
      title: "Emerald",
      source: "assets/img/diamonds-img/emerald.png",
    },
    {
      title: "Blue Diamond",
      source: "assets/img/diamonds-img/blue.png",
    },
    {
      title: "Pink Diamond",
      source: "assets/img/diamonds-img/pink.png",
    },
    {
      title: "Red Diamond",
      source: "assets/img/diamonds-img/red.png",
    },
    {
      title: "Ruby",
      source: "assets/img/diamonds-img/ruby.png",
    },
    {
      title: "Diamond",
      source: "assets/img/diamonds-img/diamond.png",
    },
    {
      title: "Gold Sapphire",
      source: "assets/img/diamonds-img/yellow-gold.png",
    },
  ]

export default InfinityStonesCollection;
