import * as React from "react"
import { Link } from "react-router-dom"
import { dAppName } from "config"
import { routeNames } from "routes"

function Home() {
  const shouldShowVideo = () => {
    return window.innerWidth > 767;
  }

  return (
    <div>
      <div className="section" style={{ position: 'relative', padding: '0px' }}>
        {shouldShowVideo() && <div>
          <video autoPlay loop muted className="hero-video-cls">
            <source src='/assets/img/diamonds-video/herovideo-big.mp4' type='video/mp4' />
          </video>
        </div>}
        <div className="container container__hero text-center hero-video-container" >
          <h2 className="video-heading" >To Infinity and Beyond</h2>
          <Link to="/collections">
            <button className="btn btn__primary">
              Mint
              <i className="fa-solid fa-up-right-from-square" />
            </button>
          </Link>
        </div>
      </div>
      <div className="section">
        <div className="container text-center">
          <h2>The Infinity Stones </h2>
          <h3>Current NFT’s available for sale</h3>
          <div className="nfts">
            {nfts.map((n, key) => (
              <div className="nfts__item" key={key}>
                <img src={n.source} alt={n.title} />
                <h5>{n.title}</h5>
              </div>
            ))}
          </div>
          <Link to="/collections">
            <button className="btn btn__primary">
              View collection
              <i className="fa-solid fa-up-right-from-square" />
            </button>
          </Link>
        </div>
      </div>
      <div className="section section__green">
        <div className="container">
          {infoSections.map((item, key) => (
            <div className="item" key={key}>
              <img src={item.source} alt={item.title} />
              <h2>{item.title}</h2>
              <p>{item.text}</p>
              <Link to={item.link}>
                <button className="btn btn__secondary">
                  {item.button}
                  <i className="fa-solid fa-up-right-from-square" />
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="section">
        <div className="container">
          <h2 className="text-center">Roadmap</h2>
          <div className="timeline">
            <div className="timeline__item timeline__item--img">
              <img src="assets/img/roadmap_icon.svg" alt="Diamond Image" />
            </div>
            <div className="timeline__item list">
              <div className="list__item">
                <div className="list__item--img">
                  <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                  <div className="divider"></div>
                </div>
                <div className="list__item--content">
                  <h4>1st quarter 2022</h4>
                  <p>
                    We start our nft series with the infinite stones which is a
                    collection of 8 rare gemstones representing the founding 8
                    In animated 3D!
                  </p>
                </div>
              </div>
              <div className="list__item">
                <div className="list__item--img">
                  <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                  <div className="divider"></div>
                </div>
                <div className="list__item--content">
                  <h4>2nd quarter 2022</h4>
                  <p>
                    Our second series of NFT’s will be 8 infinity cyborg
                    warriors powered by our infinity stones in animated 3D.
                  </p>
                </div>
              </div>
              <div className="list__item">
                <div className="list__item--img">
                  <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                  <div className="divider"></div>
                </div>
                <div className="list__item--content">
                  <h4>3nd quarter 2022</h4>
                  <p>
                    Our third series will be 8 infinity rovers powered by our
                    stones in animated 3D.
                  </p>
                </div>
              </div>
              <div className="list__item">
                <div className="list__item--img">
                  <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                </div>
                <div className="list__item--content">
                  <h4>4nd quarter 2022</h4>
                  <p>
                    Our generative 3D series of 8,888 will be released to grow
                    our brand details coming soon!
                  </p>
                </div>
              </div>
              <Link to="/roadmap">
                <button className="btn btn__secondary">
                  View Roadmap
                  <i className="fa-solid fa-up-right-from-square" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="home__roadmap">
        <h2>Roadmap</h2>
        <div className="home__roadmap__container">
          <img src="assets/img/roadmap_icon.svg" alt="Diamond Image" />

          <div className="home__roadmap__items">
            {roadmap.map((item, key) => (
              <div className="home__roadmap__item" key={key}>
                <div
                  style={
                    key == roadmap.length - 1
                      ? { justifyContent: "flex-start" }
                      : {}
                  }
                  className="home__roadmap__item__imgs">
                  <img src="assets/img/roadmap-elipse.png" alt="Elipse" />
                  <img
                    style={key == roadmap.length - 1 ? { display: "none" } : {}}
                    src="assets/img/roadmap-line.png"
                    alt="Line"
                  />
                </div>
                <div className="home__roadmap__item__desc">
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
            <Link to="/roadmap">
              <button className="btn btn-secondary">
                Roadmap
                <i className="fa-solid fa-up-right-from-square" />
              </button>
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  )
}

const nfts: NFT[] = [
  {
    title: "Amethyst",
    source: "assets/img/diamonds-img/amethyst.png",
  },
  {
    title: "Emerald",
    source: "assets/img/diamonds-img/emerald.png",
  },
  {
    title: "Blue Diamond",
    source: "assets/img/diamonds-img/blue.png",
  },
  {
    title: "Pink Diamond",
    source: "assets/img/diamonds-img/pink.png",
  },
  {
    title: "Red Diamond",
    source: "assets/img/diamonds-img/red.png",
  },
  {
    title: "Ruby",
    source: "assets/img/diamonds-img/ruby.png",
  },
  {
    title: "Diamond",
    source: "assets/img/diamonds-img/diamond.png",
  },
  {
    title: "Gold Sapphire",
    source: "assets/img/diamonds-img/yellow-gold.png",
  },
]

interface NFT {
  title: string
  source: string
}

const infoSections: INFO[] = [
  {
    title: "The founding 8.",
    text: "Our goal is to fight the forces that keep the average person working 9-5! Our NFT’s will help fight against these forces. Each of our NFT series will split the proceeds to developers, artists, our community, and our project...",
    source: "assets/img/founding_icon.svg",
    button: "About",
    link: "/about",
  },
  {
    title: "Ticker-$infinity Supply - 31.4 million.",
    text: "The $infinity token will be used in our ecosystem to purchase future limited edition  NFT’s from our brand and collaboration NFT’s from partnering brands.",
    source: "assets/img/million_icon.svg",
    button: "$Infinity",
    link: "/infinity",
  },
  {
    title: "Rewards and Benefits.",
    text: "Our community staking wallet will hold 12.5% of total sales which will be used to stake for egld rewards or farm for LKMEX rewards (member vote decided) rewards will be distributed monthly to all full set holders  Evenly or restaked (decided by member vote)...",
    source: "assets/img/rewards_icon.svg",
    button: "Benefits",
    link: "/benefits",
  },
]

interface INFO {
  title: string
  text: string
  source: string
  button: string
  link: string
}

// const roadmap: ROADMAP[] = [
//   {
//     title: "1st quarter 2022",
//     text: "We start our nft series with the infinite stones which is a collection of 8 rare gemstones representing the founding 8 In animated 3D!",
//   },
//   {
//     title: "2nd quarter 2022",
//     text: "Our second series of NFT’s will be 8 infinity cyborg warriors powered by our infinity stones in animated 3D.",
//   },
//   {
//     title: "3nd quarter 2022",
//     text: "Our third series will be 8 infinity rovers powered by our stones in animated 3D.",
//   },
//   {
//     title: "4nd quarter 2022",
//     text: "Our generative 3D series of 8,888 will be released to grow our brand details coming soon!",
//   },
// ]

// interface ROADMAP {
//   title: string
//   text: string
// }

// const Home = () => {
//   return (
//     <div className='d-flex flex-fill align-items-center container'>
//       <div className='row w-100'>
//         <div className='col-12 col-md-8 col-lg-5 mx-auto'>
//           <div className='card shadow-sm rounded p-4 border-0'>
//             <div className='card-body text-center'>
//               <h2 className='mb-3' data-testid='title'>
//                 {dAppName}
//               </h2>

//               <p className='mb-3'>Mint your Medusa now!</p>

//               <Link
//                 to={routeNames.unlock}
//                 className='btn btn-primary mt-3 text-white'
//                 data-testid='loginBtn'
//               >
//                 Login
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Home
