import React from "react"

const Infinity = () => {
  return (
    <div className="section">
      <div className="container container__hero">
        <h1>$Infinity</h1>
        <img src="assets/img/infinity-token.png" alt="" className="col-12 col-md-4 offset-md-4" />
        <h2>
          The $infinity token will be used in our ecosystem to purchase future
          limited edition NFT’s from our brand and collaboration NFT’s from
          partnering brands.
        </h2>
        <p>
          We will also have exclusive merchandise only available to infinity
          token holders. We will reward members that hold all 8 NFT’s in each
          series monthly with 888 $infinity tokens per series held.
        </p>
        <p>
          <strong>
            *Must have all 8 NFT’s in each series to qualify for the rewards
            bonus.
          </strong>
        </p>
        <a className="btn btn__primary" href="/collections">
          Get NFT <i className="fa-solid fa-up-right-from-square" />
        </a>
      </div>
    </div>
  )
}

export default Infinity
