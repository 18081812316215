import React from "react"
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  OperaWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import { routeNames } from "routes"
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';

export const UnlockRoute: () => JSX.Element = () => {
  const isLoggedIn = useGetIsLoggedIn();
  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.collections
    }
  }, [isLoggedIn])

  return (
    <div className="home d-flex flex-fill align-items-center">
      <div className="m-auto" data-testid="unlockPage">
        <div className="my-4 text-center">
          <div className="py-4 px-2 px-sm-2 mx-lg-4">
            <h2 className="mb-4">Login</h2>
            <p className="mb-4 text-uppercase">Pick a login method</p>

            <ExtensionLoginButton
              callbackRoute={routeNames.store}
              loginButtonText={"Extension"}
              className={"btn-primary"}
            />
            <WebWalletLoginButton
              callbackRoute={routeNames.store}
              loginButtonText={"Web wallet"}
              className={"btn-primary"}
            />
            <LedgerLoginButton
              loginButtonText={"Ledger"}
              callbackRoute={routeNames.store}
              className={"btn-primary"}
            />
            <WalletConnectLoginButton
              callbackRoute={routeNames.store}
              className="text-black btn-primary"
              loginButtonText={"xPortal"}
              isWalletConnectV2={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnlockRoute
