import React from 'react';
import BuyButton from './BuyButton';

const ClaimableNftsSection = (p: { whitelistLabel: string, nftsToClaim: any[], saleId: string }) => {
    return <div className='row' style={{ marginBottom: '50px' }}>
        <div className='col d-flex justify-content-center'>
            Congratulations! You're on the {p.whitelistLabel} whitelist and have some NFTs to claim!
            <div className='container'>
                <div className='row'>
                    {p.nftsToClaim.map((nft, index) =>
                        <div className='col'>
                            {nft.type === 'img'
                                ? <img src={nft.source} width='80px' />
                                : <video width='80px' autoPlay={true} muted={true} loop={true}>
                                    <source src={nft.source} />
                                </video>
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className='col'>
            <div className='d-flex justify-content-center'>
                <BuyButton saleId={p.saleId} label='Claim now' isActive={true} isFreeClaim={true} />
            </div>
        </div>
    </div>;
};

export default ClaimableNftsSection;
