import React from "react"

const About = () => {
  return (
    <div className="section">
      <div className="container container__hero">
        <h1>About</h1>
        <img src="assets/img/home-infoSection-1.png" alt="" />
        <h2>
          Our goal is to fight the forces that keep the average person working
          9-5!
        </h2>
        <p>
          Our NFT’s will help fight against these forces. Each of our NFT series
          will split the proceeds to developers, artists, our community, and our
          project.
        </p>
        <ul>
          <li>
            <i className="fa-solid fa-check"></i>
            50% of all sales proceeds will go to the developers and artists of
            our nft projects.
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            25% goes to our project
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            25% goes to community treasury which will be split
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            12.5 % goes to our community staking wallet which will share rewards
            with full set holders of our NFT’s in egld monthly and or LK Mex
            monthly (member vote decided)
          </li>
          <li>
            <i className="fa-solid fa-check"></i>
            12.5% will go to buying MultiversX NFT’s that offer passive income and
            rewards like esdt’s, staking, Airdrops
          </li>
        </ul>
        <p>
          We also will reward members with our $infinity token of which there is
          a 31.4 million supply. The $infinity token will be used in our
          ecosystem to purchase future limited edition NFT’s and exclusive
          merchandise only available to infinity token holders.
        </p>
        <a className="btn btn__primary col-12 col-md-auto" href="/collections">
          View our collection
          <i className="fa-solid fa-up-right-from-square" />
        </a>
      </div>
    </div>
  )
}

export default About
