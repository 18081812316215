// import { useGetAccountInfo, sendTransactions } from '@MultiversXnetwork/dapp-core';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { mintingSC } from 'config';

const BuyButton = (p: { label: string, isActive: boolean, saleId?: string }) => {
    const navigate = useNavigate();
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const handleClick = async () => {
        if (!loggedIn) {
            navigate('/unlock');
            return;
        }

        if (!p.saleId) {
            return;
        }

        let gasLimit = 65_000_000;
        let transaction = {
            receiver: mintingSC.scAddress,
            gasLimit: gasLimit,
            data: `buy@${p.saleId}`,
            value: mintingSC.payloadPrice
        };

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing minting transaction',
                errorMessage: 'An error has occured during minting',
                successMessage: 'Transaction successful',
                transactionDuration: 10000
            }
        });
    };

    return <button className={"btn btn__primary" + (p.isActive ? "" : " disabled")} onClick={handleClick}>
        {p.label}
        <i className="fa-solid fa-up-right-from-square" />
    </button>
}

export default BuyButton;
