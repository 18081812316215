import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { Address, AddressValue, U8Value } from '@multiversx/sdk-core';
import { fetchQueryHex } from "apiRequests/scReqs";
import { mintingSC } from "config";
import BuyButton from "./BuyButton";
import ClaimableNftsSection from "./ClaimableNftsSection";
import NFTRow from "./NftRow";
import React from "react";

const Store = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const [canClaimRocks, setCanClaimRocks] = React.useState(false);
  // const [canClaimF8, setCanClaimF8] = React.useState(false);
  const [canClaimBeni, setCanClaimBeni] = React.useState(false);

  // React.useEffect(() => {
  //   if (!isLoggedIn) return;
  //   fetchQueryHex(
  //     "canClaim",
  //     [new U8Value(20), new AddressValue(new Address(address))],
  //     mintingSC.scAddress
  //   ).then((res) => {
  //     if (res === "01") {
  //       setCanClaimRocks(true);
  //     }
  //   });
  // }, []);

  // React.useEffect(() => {
  //   if (!isLoggedIn) return;
  //   fetchQueryHex(
  //     "canClaim",
  //     [new U8Value(30), new AddressValue(new Address(address))],
  //     mintingSC.scAddress
  //   ).then((res) => {
  //     if (res === "01") {
  //       setCanClaimBeni(true);
  //     }
  //   });
  // }, []);

  // 0x13
  // React.useEffect(() => {
  //   if (!isLoggedIn) return;
  //   fetchQueryHex("canClaim", [new U8Value(19), new AddressValue(new Address(address))], mintingSC.scAddress).then((res) => {
  //     if (res === "01") {
  //       setCanClaimF8(true);
  //     }
  //   })
  // }, []);

  // React.useEffect(() => {
  //   fetchQueryHex("remaining", [new U64Value(new U32Value(9).value)], mintingSC.scAddress).then((res) => {
  //     if(res !== "-1"){
  //       setRemaining(parseInt(res, 16));
  //     }
  //   })
  // }, []);

  // check if logged in & whitelisted for free mints
  // add shops with INFINITY only
  //

  return (
    <div className="section">
      <div className="container container__hero">
        <h1 className="text-center">F8 NFT Store</h1>
        <div className="container">
          <div>
            {/* {canClaimF8 && <ClaimableNftsSection whitelistLabel='F8' nftsToClaim={claimableF8Nfts} saleId={"13"} />} */}
            {canClaimRocks && (
              <ClaimableNftsSection
                whitelistLabel="EGLD Rocks"
                nftsToClaim={claimableEgoldRocksNfts}
                saleId={"14"}
              />
            )}
            {canClaimBeni && (
              <ClaimableNftsSection
                whitelistLabel="Beni Cyborg"
                nftsToClaim={claimableF8Beni}
                saleId={"1e"}
              />
            )}
          </div>
          <div>
            <NFTRow nft={purchasableNfts[1]} saleId="0d" />
          </div>
          <div className="mt-5">
            <NFTRow nft={purchasableNfts[0]} saleId="0e" />
          </div>
        </div>
      </div>
    </div>
  );
};

const nfts: NFT[] = [
  {
    title: "Amethyst",
    source: "assets/img/diamonds-img/amethyst.png",
  },
  {
    title: "Emerald",
    source: "assets/img/diamonds-img/emerald.png",
  },
  {
    title: "Blue Diamond",
    source: "assets/img/diamonds-img/blue.png",
  },
  {
    title: "Pink Diamond",
    source: "assets/img/diamonds-img/pink.png",
  },
  {
    title: "Red Diamond",
    source: "assets/img/diamonds-img/red.png",
  },
  {
    title: "Ruby",
    source: "assets/img/diamonds-img/ruby.png",
  },
  {
    title: "Diamond",
    source: "assets/img/diamonds-img/diamond.png",
  },
  {
    title: "Gold Sapphire",
    source: "assets/img/diamonds-img/yellow-gold.png",
  },
];

const purchasableNfts: NFT[] = [
  {
    title: "Illuminated",
    source: "assets/img/diamonds-video/Illuminated.mp4",
  },
  {
    title: "Lightning",
    source: "assets/img/diamonds-video/Lightning.mp4",
  },
];

const claimableF8Nfts: NFT[] = [
  {
    title: "Infinity Coin",
    source: "assets/img/diamonds-video/infinity-coin.mp4",
  },
  {
    title: "Rainbow Diamond",
    source: "assets/img/diamonds-video/rainbow-diamond.mp4",
  },
  {
    title: "F8 Ferrari",
    source: "assets/img/collab-img/f8ferrari.jpg",
    type: "img",
  },
];

const claimableEgoldRocksNfts: NFT[] = [
  {
    title: "EGOLD Rocks",
    source: "assets/img/diamonds-video/egld-rock.mp4",
  },
];

const claimableF8Beni: NFT[] = [
  {
    title: "Beni Cyborg",
    source: "assets/f8beni.mp4",
  },
];

interface NFT {
  title: string;
  source: string;
  type?: string;
}

export default Store;
