import React from "react"
import InfinityStonesCollection from "./InfinityStonesCollection";
import InfinityCyborgs from "./InfinityCyborgs";

const Collections = () => {

  // check if logged in & whitelisted for free mints
  // add shops with INFINITY only
  // 

  return (
    <div className="section">
      <div className="container container__hero">
        <h1 className="text-center">Our NFT Collections</h1>
        <div className="text-center mb-5">
          <h5 className="mb-5">
            Our goal is to fight the forces that keep the average person working
            9-5!
          </h5>
        </div>
        <InfinityCyborgs />
        <InfinityStonesCollection />
      </div>
    </div>
  )
}

export default Collections
