import { U32Value, U64Value } from '@multiversx/sdk-core';
import { fetchQueryHex } from "apiRequests/scReqs";
import { mintingSC } from "config";
import React from "react";
import { NFT } from "types";
import BuyButton from "./BuyButton";

const InfinityCyborgs = () => {
  const [remaining, setRemaining] = React.useState(0);
  React.useEffect(() => {
    fetchQueryHex("remaining", [new U64Value(new U32Value(28).value)], mintingSC.scAddress).then((res) => {
      if (res !== "-1") {
        setRemaining(parseInt(res, 16));
      }
    })
  }, []);
  return (
    <div className="mb-5">
      <div className="text-center">
        <h1 className="">The Infinity Cyborgs</h1>
        {/* <BuyButton label='Buy now' isActive={true} saleId="1d"/> */}
        {/* <h6 className="mb-5">{remaining}/111 remaining</h6> */}
      </div>
      <div className="nfts">
        {nfts.map((n, key) => (
          <div className="nfts__item" key={key} style={{ padding: '10px' }}>
            <img src={n.source} alt={n.title} />
            <h5>{n.title}</h5>
          </div>
        ))}
      </div>
      <div className="text-center">
        <BuyButton label='Sold out' isActive={false} />
      </div>
    </div>
  )
}

const nfts: NFT[] = [
  {
    title: "Amethyst",
    source: "assets/img/cyborgs-img/amethyst.jpeg",
  },
  {
    title: "Emerald",
    source: "assets/img/cyborgs-img/emerald.jpeg",
  },
  {
    title: "Blue Diamond",
    source: "assets/img/cyborgs-img/blue.jpeg",
  },
  {
    title: "Pink Diamond",
    source: "assets/img/cyborgs-img/pink.jpeg",
  },
  {
    title: "Red Diamond",
    source: "assets/img/cyborgs-img/red.jpeg",
  },
  {
    title: "Ruby",
    source: "assets/img/cyborgs-img/ruby.jpeg",
  },
  {
    title: "Diamond",
    source: "assets/img/cyborgs-img/diamond.jpeg",
  },
  {
    title: "Gold Sapphire",
    source: "assets/img/cyborgs-img/gold.jpeg",
  },
]

export default InfinityCyborgs;
