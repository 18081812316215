import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

export const dAppName = 'Founding 8';
export const environment = EnvironmentsEnum.mainnet;

export const mintingSC = {
  scAddress: 'erd1qqqqqqqqqqqqqpgq2p6cxn7k7gpftw60pdqxgsy0dnfetnhqyl5scvxdak',
  payloadPrice: 2_000_000_000_000_000_000,
};

export const gatewayAddress = 'https://gateway.multiversX.com';
export const walletConnectV2ProjectId = '8f5903791a2d1acbb2ae56eef0f09d9e';
