import * as React from "react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "react-router-dom"

const PageNotFound = () => {
  const { pathname } = useLocation()
  return (
    <div className="container text-center">
      <h1>Page not found</h1>
      <p>{pathname}</p>
    </div>
  )
}

export default PageNotFound
