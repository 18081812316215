import {
  Address,
  ContractFunction,
  SmartContract,
  TypedValue
} from "@multiversx/sdk-core";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { gatewayAddress } from 'config';

export const fetchQueryHex = (
  method: string,
  args: TypedValue[],
  address: string
) =>
  new Promise<string>(async (resolve, reject) => {
    const contract = new SmartContract({ address: new Address(address) });
    const provider = new ProxyNetworkProvider(gatewayAddress || '');
    const query = contract.createQuery({
      func: new ContractFunction(method),
      args: args
    });
    const response = await provider.queryContract(query);
    if (response.returnCode === 'ok') {
      if (response.returnData !== undefined) {
        const [encoded] = response.returnData;
        if (!encoded) resolve('-1');
        const decoded = Buffer.from(encoded || '', 'base64').toString('hex');
        resolve(decoded);
      }
    } else {
      reject('Something went wrong');
    }
  });

export const fetchQueryResponse = (
  method: string,
  args: TypedValue[],
  address: string
) =>
  new Promise<any>(async (resolve, reject) => {
    const contract = new SmartContract({ address: new Address(address) });
    const provider = new ProxyNetworkProvider(gatewayAddress || '');
    
    const query = contract.createQuery({
      func: new ContractFunction(method),
      args: args
    });

    const response = await provider.queryContract(query);
    if (response.returnCode === 'ok') {
      resolve(response);
    } else {
      reject('Something went wrong');
    }
  });
