import React from "react"

const Benefits = () => {
  return (
    <div className="section">
      <div className="container container__hero">
        <h1 className="page-title">Rewards and Benefits</h1>
        <img src="assets/img/rewards_icon.svg" alt="" />
        <p>
          Our community staking wallet will hold 12.5% of total sales which will
          be used to stake for egld rewards or farm for LKMEX rewards (member
          vote decided) rewards will be distributed monthly to all full set
          holders Evenly or restaked (decided by member vote).
        </p>
        <p>
          Our community NFT wallet will hold 12.5% of total sales to purchase
          MultiversX NFT’s that offer passive income like staking rewards, esdt
          rewards, airdrop rewards, bonus NFT’s..
        </p>
        <ul>
          <li>
            <i className="fa-solid fa-check benefits__checklist__icon"></i>
            Rewards will be distributed monthly evenly or raffled ( decided by
            member vote ) to all full set holders.
          </li>
          <li>
            <i className="fa-solid fa-check benefits__checklist__icon"></i>Full
            set Holders of our nft series will also be rewarded with our
            $infinity esdt at 888 per month per series held in wallet.
          </li>
          <li>
            <i className="fa-solid fa-check benefits__checklist__icon"></i>
            Airdrop NFT’s will be dropped to all full series holders
            periodically from our brand and collaboration brands.
          </li>
        </ul>
        <a className="btn btn__primary col-12 col-md-auto" href="/collections">
          View our collection
          <i className="fa-solid fa-up-right-from-square" />
        </a>
      </div>
    </div>
  )
}

export default Benefits
