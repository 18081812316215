import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { mintingSC } from 'config';

const BuyButton = (p: { label: string, isActive: boolean, saleId: string, isFreeClaim: boolean }) => {
    const navigate = useNavigate();
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const handleClick = async () => {
        if (!loggedIn) {
            navigate('/unlock');
            return;
        }

        if (p.isFreeClaim) {
            await handleFreeClaim();
            return;
        }

        // let gasLimit = 20_000_000;
        let gasLimit = 600_000_000;
        let amount = 4444000000000000000000;

        let transaction = {
            receiver: mintingSC.scAddress,
            gasLimit: gasLimit,
            data: `ESDTTransfer@${Buffer.from("INFINITY-58f481").toString('hex')}@${amount.toString(16)}@${Buffer.from("buy").toString('hex')}@${p.saleId}`,
            value: '0'
        };

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing mint transaction',
                errorMessage: 'An error has occured during minting',
                successMessage: 'Transaction successful',
                transactionDuration: 10000
            }
        });
    };

    const handleFreeClaim = async () => {
        let gasLimit = 35_000_000;
        if (address === 'erd1d70veu5gvlwfdja6c8tespu62neckzmyrvvhn3hwylt43l2uy8tqkzvrzv') {
            gasLimit = 350_000_000;
        }

        let transaction = {
            receiver: mintingSC.scAddress,
            gasLimit: gasLimit,
            data: `claim@${p.saleId}`,
            value: '0'
        };

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing claim transaction',
                errorMessage: 'An error has occured during claiming',
                successMessage: 'Transaction successful',
                transactionDuration: 10000
            }
        });
    }

    return <button className={"btn btn__primary" + (p.isActive ? "" : " disabled")} onClick={handleClick}>
        {p.label}
        <i className="fa-solid fa-up-right-from-square" />
    </button>
}

export default BuyButton;
