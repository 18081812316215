import React from "react"
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import Layout from "components/Layout"
import PageNotFound from "pages/PageNotFound"
import { routeNames } from "routes"
import routes from "routes"
import { environment, walletConnectV2ProjectId } from "config"
import UnlockPage from "pages/UnlockPage"
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import {
  DappProvider,
} from '@multiversx/sdk-dapp/wrappers';

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{ name: "customConfig", apiTimeout: 6000, walletConnectV2ProjectId }}>
        <Layout>
          <TransactionsToastList className="transactions-toast-list" />
          <NotificationModal />
          <SignTransactionsModals className="custom-class-for-modals" />
          <Routes>
            <Route path={routeNames.unlock} element={<UnlockPage />} />
            {routes.map((route: any, index: number) => (
              <Route
                path={route.path}
                key={"route-key-" + index}
                element={<route.component />}
              />
            ))}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
  )
}

export default App
