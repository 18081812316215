import { dAppName } from "config"
import withPageTitle from "./components/PageTitle"
import Home from "./pages/Home"
import Infinity from "pages/Infinity"
import Benefits from "pages/Benefits"
import Roadmap from "pages/Roadmap"
import About from "pages/About"
import Collections from "pages/Collections"
import Store from "pages/Store"

export const routeNames = {
  home: "/",
  infinity: "/infinity",
  benefits: "/benefits",
  roadmap: "/roadmap",
  about: "/about",
  collections: "/collections",
  unlock: "/unlock",
  walletconnect: "/walletconnect",
  store: '/store'
}

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: "Home",
    component: Home,
  },
  {
    path: routeNames.infinity,
    title: "Infinity",
    component: Infinity,
  },
  {
    path: routeNames.roadmap,
    title: "Roadmap",
    component: Roadmap,
  },
  {
    path: routeNames.about,
    title: "About",
    component: About,
  },
  {
    path: routeNames.collections,
    title: "Collections",
    component: Collections,
  },
  {
    path: routeNames.benefits,
    title: "Benefits",
    component: Benefits,
  },
  {
    path: routeNames.store,
    title: "Store",
    component: Store,
  },
]

const mappedRoutes = routes.map(route => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`

  const requiresAuth = Boolean(route.authenticatedRoute)
  const wrappedComponent = withPageTitle(title, route.component)

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  }
})

export default mappedRoutes
