import { U32Value, U64Value } from '@multiversx/sdk-core';
import { fetchQueryHex } from 'apiRequests/scReqs';
import { mintingSC } from 'config';
import React from 'react';
import BuyButton from "./BuyButton"

const NFTRow = (p: { nft: any, saleId: string }) => {
  const [supply, setSupply] = React.useState(0);


  React.useEffect(() => {
    fetchQueryHex("remaining", [new U64Value(new U32Value(parseInt(p.saleId, 16)).value)], mintingSC.scAddress).then((res) => {
      if(res !== "-1"){
        setSupply(parseInt(res, 16));
      }
    })
  }, []);

  return (
    <div className="row">
      <div className="col-lg-6 d-flex justify-content-center">
        <div className="nfts__item">
          <div className="d-flex justify-content-center">
            <video width='300px' autoPlay={true} muted={true} loop={true}>
              <source src={p?.nft.source} />
            </video>
          </div>
          <h5>{p?.nft.title}</h5>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="d-flex justify-content-center">
          <div>
            <p>Mint price: 4444 $INFINITY</p>
            <p>Supply: {supply} / 111</p><br />
            <BuyButton label='Buy now' isActive={true} saleId={p.saleId} isFreeClaim={false} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default NFTRow;
